@font-face {
  font-family: 'Lexend';
  src: local('Lexend'), url(../fonts/Lexend-VariableFont_wght.ttf) format('truetype');
}

$font-size-root: 16px;
$font-size-base: 1rem;
$font-family-sans-serif: "Lexend", sans-serif;
$body-color: #00393f;

$utilities: map-merge(
  $utilities,
  (
    "font-size":
      map-merge(
        map-get($utilities, "font-size"),
        (
          values:
            map-merge(
              map-get(map-get($utilities, "font-size"), "values"),
              (
                7: $font-size-base * 0.875,
                8: $font-size-base * 0.75,
                9: $font-size-base * 0.625,
              )
            ),
        )
      ),
  )
);

$utilities: map-merge(
  $utilities,
  (
    "text-align":
      map-merge(
        map-get($utilities, "text-align"),
        (
          values:
            map-merge(
              map-get(map-get($utilities, "text-align"), "values"),
              (
                justify: justify,
              )
            ),
        )
      ),
  )
);
